<template>
    <div>
        <div class="text-left view-reports mt-5 mb-5">
            <!--            <nav>-->
            <!--                <div class="nav nav-tabs" id="nav-tab" role="tablist">-->
            <!--                    <a-->
            <!--                            class="nav-item nav-link active"-->
            <!--                            id="nav-home-tab"-->
            <!--                            data-toggle="tab"-->
            <!--                            href="#nav-home"-->
            <!--                            role="tab"-->
            <!--                            aria-controls="nav-home"-->
            <!--                            aria-selected="true"-->
            <!--                            @click="changeTab('DashboardChart')"-->
            <!--                    >Chart View</a>-->

            <!--                    <a-->
            <!--                            class="nav-item nav-link"-->
            <!--                            id="nav-profile-tab"-->
            <!--                            data-toggle="tab"-->
            <!--                            href="#nav-profile"-->
            <!--                            role="tab"-->
            <!--                            aria-controls="nav-profile"-->
            <!--                            aria-selected="false"-->
            <!--                            style="margin-left:2%;"-->
            <!--                            @click="changeTab('DashboardCard')"-->
            <!--                    >Text View</a>-->
            <!--                </div>-->
            <!--            </nav>-->
            <div class="tab-content" id="nav-tabContent">
                <div
                        class="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                        :class="{'active':currentTabComponent==='DashboardChart'}"
                ></div>
                <div
                        class="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                        :class="{'active':currentTabComponent==='DashboardCard'}"
                ></div>
            </div>
        </div>
        <component :is="currentTabComponent"/>
        <!-- <BackOfficeTour/> -->
    </div>
</template>

<script>
    export default {
        components : {
            DashboardChart : () => import("../views/Dashboard"),
            DashboardCard : () => import("../views/mainDashboard"),
         },
        name : "tasks",
        data () {
            return {
                 currentTabComponent : "DashboardCard"
            };
        },
        computed : {
        },
        mounted () {
            this.$store.commit ( "toggle_loader", false );
        },
        methods : {
            changeTab ( tab ) {
                this.$store.commit ( "toggle_loader", true );
                // this.$store.commit('set_dashboard', tab)
                this.currentTabComponent = tab;
            }
        }
    };
</script>

 <style>
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        /*color: black !important;*/
        background-color: unset !important;
        border-bottom: 3px solid #2758f6;
        font-size: 15px;
        font-family: "poppins" !important;
    }

    /*.nav-tabs .nav-item.show .nav-link,*/
    /*.nav-tabs .nav-link.active {*/
    /*    color: #495057;*/
    /*    background-color: #fff;*/
    /*}*/

    a {
        /*color: black !important;*/
        text-decoration: none;
        background-color: transparent;
    }

    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
        /*border-color: #a5a3a3;*/
    }

    .nav-tabs .nav-link {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid #a5a3a3;
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        /*color: black;*/
        /*background-color: #fff;*/
        /* border-color: #dee2e6 #dee2e6 #fff; */
    }

    .nav-tabs {
        border-bottom: none;
        margin-top: 2%;
    }



    /*@media (max-width: 350px) and (min-width: 301px) {*/
    /*    .nav-tabs {display: block;*/
    /*    }*/
    /*}*/
</style>
